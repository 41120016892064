import * as React from 'react';
import { Box, Grid, Typography, useMediaQuery, useTheme, Link } from '@mui/material';
import Patio from '../media/star_one.png';
import { Link as RouterLink} from 'react-router-dom';
import Services from './Services';
import { phoneNumber, phoneNumberViewable } from '../util/Services';
import Info from '../components/Info';
import { useEffect } from 'react';

const Home: React.FC = () => {
  const theme = useTheme();
  const isMdOrLess = useMediaQuery(theme.breakpoints.down('md'));
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <Grid container minHeight={'100%'} className='home-view' flexDirection={isMdOrLess ? 'row-reverse' : 'row'}>
      <Grid container item md={12} direction={'column'}>
        <Grid container item sx={{ marginBottom: 'auto' }} justifyContent={'center'}>
          <Grid item style={{ width: '100%' }} >
            <Box className='landing-image' sx={{
              width: '100%',
              height: '400px',
              backgroundImage: `url(${Patio})`,
              backgroundPosition: 'top',
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              border: 'none',
              zIndex: -2,
              maskImage: 'linear-gradient(to bottom, rgba(0,0,0,1), rgba(0,0,0,.8), rgba(0,0,0,.1), rgba(0,0,0,0) 100%)'
            }}>
            </Box>
          </Grid>
        </Grid>
        <Grid item container style={{ width: '100%' }} marginTop={'-140px'} justifyContent={'center'} padding={'20px 0px 0px 0px'}>
          <Typography letterSpacing={0} fontWeight={500} fontSize={isMdOrLess ? 36 : 80} align={'center'} color={'primary'} sx={{ fontFamily: 'Lora, serif'}}>
            {'Simone Home & Garden'}
          </Typography>
        </Grid>
      </Grid>
      <Grid item container style={{ width: '100%' }} justifyContent={'center'} alignContent={'center'} alignItems={'center'} justifyItems={'center'} padding={'00px 20px'} flexDirection={'column'}>
        <Typography fontWeight={400} fontSize={isMdOrLess ? 22 : 30} align={'center'} padding={'0px 30px'} variant={'body2'} color={'primary'} >
          {'Quality Home Improvements'}
        </Typography>
        <Typography fontWeight={500} maxWidth={'740px'} fontSize={isMdOrLess ? 14 : 16} align={'center'} padding={'12px 30px'} variant={'body2'} color={'primary'}>
          {`From landscaping and patio construction to deck repair and interior renovations, I provide top-notch services to enhance every aspect of your living space.
            Going beyond the basics, I offer a diverse range of additional services tailored to meet all your home improvement needs.`}
        </Typography>
      </Grid>
      <Services/>
      {/* Increase the network loading priority of the background image. */}
      <img
        style={{ display: 'none' }}
        src={Patio}
        alt="increase priority"
      />
    </Grid>
  );
}

export default Home;
