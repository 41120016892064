import * as React from 'react';
import { Box, Grid, Icon, Tab, Tabs, useTheme } from '@mui/material';
import Shovel from '../media/shovel_dark.png';
import Hammer from '../media/hammer_dark.png';
import ServiceCard from '../components/ServiceCard';
import { gardenServices, homeServices, ServiceType } from '../util/Services';
import Info from '../components/Info';
import { useEffect } from 'react';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}
const Home: React.FC = () => {
  const garden = [...gardenServices];
  const home = [...homeServices];
  const [value, setValue] = React.useState(1);
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  return (
    <Grid container flexDirection={'row'} justifyContent={'center'} alignContent={'center'} minHeight={'100%'} width={'100%'} className='services-view'>
      <Info />
      <Grid xs={12} item justifyContent={'center'} alignContent={'center'}>
        <Tabs value={value} onChange={handleChange} centered>
          <Tab label="Home" sx={{ width: 140 }} iconPosition={'end'} icon={<Icon><img width={24} src={Hammer} alt="simone_home_and_garden_hammer" /></Icon>}/>
          <Tab label="Garden" sx={{ width: 140 }} iconPosition={'start'} icon={<Icon><img width={24} src={Shovel} alt="simone_home_and_garden_shovel" /></Icon>}/>
        </Tabs>
      </Grid>
      <Grid item container xs={12} justifyContent={'center'} justifyItems={'center'}>
        <CustomTabPanel value={value} index={0}>
          <Grid container justifyContent={'center'} alignContent={'center'} padding={0} spacing={3}>
            {home.map((service: ServiceType) => {
              return (
                <Grid item container justifyContent={'center'} xs={12} sm={6} md={6} lg={4}>
                  <ServiceCard service={service} />
                </Grid>
              );
            })}
          </Grid>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <Grid container justifyContent={'center'} alignContent={'center'} padding={0} spacing={3}>
            {garden.map((service: ServiceType) => {
              return (
                <Grid item container justifyContent={'center'} xs={12} md={6} lg={4}>
                  <ServiceCard service={service} />
                </Grid>
              );
            })}
          </Grid>
        </CustomTabPanel>
      </Grid>
    </Grid>
  );
}

export default Home;
